import { Fragment } from "react"

import Layout from "../components/Layout"
import DefaultHeader from "../components/DefaultHeader"
import { Flex, Heading } from "rebass/styled-components"
import Section from "../components/Section"
import Background from "../components/Background"
import { centerHorizontally } from "../styles/constants"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import * as React from "react"

const DisclaimerPage = () => (
  <Layout>
    <SEO title="Disclaimer" />
    <DefaultHeader />
    <div>
      <Section.Container id="disclaimer" Background={Background}>
        <Fragment>
          <p style={{ paddingTop: "16px" }}>
            <strong>Last updated May 31, 2020</strong>
          </p>
          <Heading
            as="h3"
            color="primary"
            fontSize={[3, 4]}
            mb={[2, 3, 4]}
            textAlign="center"
            style={centerHorizontally}
          >
            WEBSITE DISCLAIMER
          </Heading>
          <p>
            The information provided by BurritoApps (“we,” “us” or “our”) on{" "}
            <a
              href="https://www.burritoapps.de/"
              rel="noreferrer"
              target="_blank"
            >
              www.burritoapps.de
            </a>{" "}
            (the “Site”) and our mobile application is for general informational
            purposes only. All information on the Site and our mobile
            application is provided in good faith, however we make no
            representation or warranty of any kind, express or implied,
            regarding the accuracy, adequacy, validity, reliability,
            availability or completeness of any information on the Site or our
            mobile application. According to statutory provisions, we are
            furthermore responsible for our own content on these web pages. In
            this matter, please note that we are not obliged to monitor the
            transmitted or saved information of third parties, or investigate
            circumstances pointing to illegal activity. Our obligations to
            remove or block the use of information under generally applicable
            laws remain unaffected by this as per §§ 8 to 10 of the Telemedia
            Act (TMG). UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU
            FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
            OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION
            PROVIDED ON THE SITE AND OUR MOBILE APPLICATION. YOUR USE OF THE
            SITE AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION
            ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.
          </p>
          <Heading
            as="h3"
            color="primary"
            fontSize={[3, 4]}
            mb={[2, 3, 4]}
            textAlign="center"
            style={centerHorizontally}
          >
            EXTERNAL LINKS DISCLAIMER
          </Heading>
          <p>
            The Site and our mobile application may contain (or you may be sent
            through the Site or our mobile application) links to other websites
            or content belonging to or originating from third parties or links
            to websites and features in banners or other advertising. Such
            external links are not investigated, monitored, or checked for
            accuracy, adequacy, validity, reliability, availability or
            completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION
            OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY
            WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE
            WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
            ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR
            SERVICES.
          </p>
          <Heading
            as="h3"
            color="primary"
            fontSize={[3, 4]}
            mb={[2, 3, 4]}
            textAlign="center"
            style={centerHorizontally}
          >
            PROFESSIONAL DISCLAIMER
          </Heading>
          <p>
            The Site cannot and does not contain legal advice. The legal
            information is provided for general informational and educational
            purposes only and is not a substitute for professional advice.
            Accordingly, before taking any actions based upon such information,
            we encourage you to consult with the appropriate professionals. We
            do not provide any kind of legal advice. THE USE OR RELIANCE OF ANY
            INFORMATION CONTAINED ON THIS SITE OR OUR MOBILE APPLICATION IS
            SOLELY AT YOUR OWN RISK.
          </p>
          <Heading
            as="h3"
            color="primary"
            fontSize={[3, 4]}
            mb={[2, 3, 4]}
            textAlign="center"
            style={centerHorizontally}
          >
            COPYRIGHT
          </Heading>
          <p>
            Our web pages and their contents are subject to German copyright
            law. Unless expressly permitted by law, every form of utilizing,
            reproducing or processing works subject to copyright protection on
            our web pages requires the prior consent of the respective owner of
            the rights. Individual reproductions of a work are only allowed for
            private use. The materials from these pages are copyrighted and any
            unauthorized use may violate copyright laws.
          </p>
          <Flex
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          ></Flex>
        </Fragment>
      </Section.Container>
    </div>
    <Footer />
  </Layout>
)

export default DisclaimerPage
